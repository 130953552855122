$(function(){
    $('#menu-wrapper').data('size','big');
    $(window).scroll(function(){
    if($(document).scrollTop() > ($('#nav-top').offset().top + $('#nav-top').outerHeight()))
    {
        if($('#menu-wrapper').data('size') == 'big')
        {
            $('#menu-wrapper').data('size','small');
            $('#menu-wrapper').addClass('fixed');
        }
    }
    else
    {
        if($('#menu-wrapper').data('size') == 'small')
        {
            $('#menu-wrapper').data('size','big');
            $('#menu-wrapper').removeClass('fixed');
        }  
    }
});
});
$(window).on('load', function () {
    if (typeof ($('#main-content')) != 'undefined') {
        navHeight = ($(window).width > 768) ? 110: 68;
        $('body').animate(
                {scrollTop: $('#main-content').offset().top- $('#menu-wrapper').outerHeight() - navHeight},
                '500'
                );
        if($('#menu-wrapper').data('size') == 'big')
        {
            $('#menu-wrapper').data('size','small');
            $('#menu-wrapper').addClass('fixed');
        }
    }
});
